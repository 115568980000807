<template>
  <v-content>
    <v-container>
      <v-col :cols="12" :sm="12" class="d-flex justify-center">
        <span class="fontheaderBold">ยี่ห้อสินค้า</span>
      </v-col>
      <BrandCard />
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <v-col :cols="12" :sm="12" class="d-flex justify-center">
        <span class="fontheaderBold">ประเภทสินค้า</span>
      </v-col>
      <CategoryCard />
    </v-container>
  </v-content>
</template>

<script>
import BrandCard from '../components/BrandCard'
import CategoryCard from '../components/CategoryCard'
export default {
  name: 'Product',
  components: {
    BrandCard,
    CategoryCard,
  },
}
</script>
