<template>
  <div class="customGrid">
    <v-col class="Card pa-0 mx-1 my-0" v-for="(item, index) in category" :key="index">
      <v-row @click="clickCategory(item.category_id)">
        <v-col :cols="6" class="py-0 px-1 d-flex justify-center align-center">
          <div class="text-center" style="line-height: 1">
            <span class="headerBold">{{ item.name }}</span>
            <span class="textViewall">View all</span>
          </div>
        </v-col>
        <v-col :cols="6" class="px-1 py-0">
          <v-img :src="item.image" contain width="100" height="100" />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'CategoryCard',
  data() {
    return {
      category: [],
    }
  },
  methods: {
    async getCategory() {
      const self = this
      const res = await self.$axios({
        method: 'GET',
        url: '/api/categorylist',
      })
      if (res.data.status === 'failed') {
        self.$modal.show({
          title: `ERROR: ${res.data.message}`,
          isOne: true,
        })
      }
      self.category = res.data
    },
    clickCategory(id) {
      this.$router.push(`/productlist?category_id=${id}`)
    },
  },
  mounted() {
    this.getCategory()
  },
}
</script>

<style lang="scss" scoped>
.headerBold {
  font-size: 18px;
}
.customGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
</style>
