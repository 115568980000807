<template>
  <div class="customGrid">
    <v-col
      class="Card my-0 mx-1 d-flex flex-column align-center"
      v-for="(item, index) in brand"
      :key="index"
      @click="clickBrand(item.brand_id)"
    >
      <v-img :src="item.image" contain width="100" height="50" />
      <span class="textViewall">View all</span>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'BrandCard',
  data() {
    return {
      brand: [],
    }
  },
  methods: {
    async getBrand() {
      const res = await this.$axios({
        method: 'GET',
        url: '/api/brandlist',
      })
      if (res.data.status === 'failed') {
        this.$modal.show({
          title: `ERROR: ${res.data.message}`,
          isOne: true,
        })
      }
      this.brand = this.sortBrand(res.data)
    },
    sortBrand(brand) {
      return brand.sort((a, b) => a.priority - b.priority)
    },
    clickBrand(id) {
      this.$router.push(`/productlist?brand_id=${id}`)
    },
  },
  mounted() {
    this.getBrand()
  },
}
</script>

<style lang="scss" scoped>
.customGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
</style>
